<template>
  <div class="container">
    <img src="./success.png" alt="">
    <p>您已提交成功，工作人员会与您联系！</p>
  </div>
</template>

<script>
export default {
  name: "Success"
}
</script>

<style scoped>
.container{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
