<template>
<h1>服务器异常</h1>
</template>

<script>
export default {
  name: "Error"
}
</script>

<style scoped>

</style>
